import backendRedirect from '../backendRedirect'
import { getDevServer } from './devMode'

const SERVER_ROOT = '/api' // in Search used only for ApiChildHandler "view"
const defaultServer = backendRedirect.apiTrafficManager // (not used by Search backend) + SERVER_ROOT
const devServer = getDevServer('search', defaultServer)
const SERVER = devServer || defaultServer
const SUGGESTER = '/suggester'
const hebrewKeyboard = {
  'q': '\u002F',
  'w': '\u0027',
  'e': '\u05E7',
  'r': '\u05E8',
  't': '\u05D0',
  'y': '\u05D8',
  'u': '\u05D5',
  'i': '\u05DF',
  'o': '\u05DD',
  'p': '\u05E4',
  'a': '\u05E9',
  's': '\u05D3',
  'd': '\u05D2',
  'f': '\u05DB',
  'g': '\u05E2',
  'h': '\u05D9',
  'j': '\u05D7',
  'k': '\u05DC',
  'l': '\u05DA',
  ';': '\u05E3',
  'z': '\u05D6',
  'x': '\u05E1',
  'c': '\u05D1',
  'v': '\u05D4',
  'b': '\u05E0',
  'n': '\u05DE',
  'm': '\u05E6',
  ',': '\u05EA',
  '.': '\u05E5'
}

// unused
function englishToHebrew(query) {
  var modifiedQuery = query
  for (var i = 0; i < modifiedQuery.length; i++) {
    var char = modifiedQuery.charAt(i).toLowerCase()
    if (char in hebrewKeyboard) {
      var key = hebrewKeyboard[char]
      modifiedQuery = modifiedQuery.substring(0, i) + key + modifiedQuery.substring(i + 1)
    }
  }
  return modifiedQuery
}

const corpusType = backendRedirect.corpusType
const DISABLE_ENGLISH_UI = corpusType === 'dicta-library'
const DEFAULT_ORDER = process.env.VUE_APP_SEARCH_TYPE === 'Bible' ? 'tanach order' : 'relevance'
const HAS_TEAMIM = process.env.VUE_APP_SEARCH_TYPE === 'Bible'
const REMOVABLE_SHEMOS = process.env.VUE_APP_SEARCH_TYPE === 'Bible'

const config = {
  SERVER_ROOT, SERVER, SUGGESTER, DEFAULT_ORDER,
  hebrewKeyboard, englishToHebrew,
  corpusType, DISABLE_ENGLISH_UI, HAS_TEAMIM, REMOVABLE_SHEMOS
}
export default config
