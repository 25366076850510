import { render, staticRenderFns } from "./MeaningsMobile.vue?vue&type=template&id=10a75db3&scoped=true"
import script from "./MeaningsMobile.vue?vue&type=script&lang=js"
export * from "./MeaningsMobile.vue?vue&type=script&lang=js"
import style0 from "../../assets/style/common-mobile-sidebar.css?vue&type=style&index=0&id=10a75db3&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10a75db3",
  null
  
)

export default component.exports